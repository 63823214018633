import {ElementRef, EventEmitter, Injectable, OnChanges, SimpleChanges, ViewChildren} from '@angular/core';
import {HttpClient, HttpEventType} from "@angular/common/http";
import {User} from "../model/user";
import {forkJoin, Observable} from "rxjs";
import {Documents} from "../model/documents";
import {Transaction} from "../model/transaction";
import {IonInput, isPlatform, LoadingController, NavController, Platform} from "@ionic/angular";
import {EscrowDeposit} from "../model/escrow-deposit";
import {EscrowDisburs} from "../model/escrow-disburs";
import {CancelTransaction} from "../model/cancel-transaction";
import {Property} from "../model/property";
import {StorageService} from "./storage.service";
import {Keys} from "../common/keys";
import {Notifications} from "../model/notifications";
import {Router} from "@angular/router";
import {Browser} from "@capacitor/browser";
import {error} from "protractor";
import {Instagram} from "@awesome-cordova-plugins/instagram/ngx";

/*
* Transaction steps:
* 1 - sale-lease
* 2 - client-type
* 3 - clients
* 4 - properties
* 5 - property-owner-name
* 6 - transaction-data
* 7 - add-transaction
* 8 - summary
* */

/*
* Transaction steps from property:
* 1 - transaction-data
* 2 - add-transaction
* 3 - summary
* */

/*
* Transaction steps from client:
* 1 - sale-lease
* 2 - client-type
* 3 - property-type
* 4 - property-more
* 5 - property-data
* 6 - property-owner-name
* 7 - transaction-data
* 8 - add-transaction
* 9 - summary
* */

/*
* Offer steps:
* 1 - sale-lease
* 2 - clients
* 3 - property-type
* 4 - property-more
* 5 - property-data
* 6 - property-owner-name
* 7 - offer-more
* 8 - add-transaction
* 9 - summary
* */

/*
* Search steps:
* 1 - sale-lease
* 2 - clients
* 3 - search-property-type
* 4 - search-specs
* 5 - display-type
* 6 - results-type
* */

/*
* PropertySubType
*
Condominium
Single Family Residence
Apartment
Townhouse
Multi Family
Mobile Home
Villa
Lease
Commercial
Residential
Efficiency
Duplex
Stock Cooperative
Industrial
Quadruplex
Dockominium
Triplex
Agriculture
* */

/*
* PropertyType
*
* Residential
* Residential Income
* Commercial Land
* Land/Boat Docks
* Business Opportunity
* Commercial Sale
* Residential Lease
* */


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  landBoat = "Land/Boat Docks"
  busOpp = "Business Opportunity"
  resi = "Residential Income"
  resL = "Residential Lease"
  canImpersonate = false

  getPropTypeLabel(type: string): string {
    switch (type) {
      case this.landBoat:
        return "RLD "
      case this.busOpp:
        return "BUS "
      case this.resi:
        return "RIN "
      case this.resL:
        return "RNT "
      default:
        return ""
    }
  }

  folderDidEnter: EventEmitter<any> = new EventEmitter()

  getPropTypeName(id) {
    var temp = this.allpropertytypeslist.find(e => e.id == id)
    return temp ? temp.type : 'All categories'
  }

  allpropertytypeslist = [
    {
      id: 1,
      type: "Single Family/Condo (RE1/RE2)",
      name: '',
      subTypes: [
        'Apartment',
        'Single Family Residence',
        'Townhouse',
        'Villa'
        // 'Apartment',
        // 'Condominium',
        // 'Dockominium',
        // 'Duplex',
        // 'Hotel/Motel',
        // 'Mobile Home',
        // 'Multi Family',
        // 'Residential',
        // 'Single Family Residence',
        // 'Stock Cooperative',
        // 'Timeshare',
        // 'Townhouse',
        // 'Villa'
      ]
    },
    {
      id: 2,
      type: "RLD Land/Boats/Docks",
      subTypes: [
        'Agriculture',
        'Commercial',
        'Dockominium',
        'Duplex',
        'Income/MultiFamily',
        'Mixed Use',
        'Multi Family',
        'Quadruplex',
        'Residential',
        'Single Family Residence',
        'Stock Cooperative',
        'Triplex'
      ]
    },
    {
      id: 3,
      type: "RIN Res Income",
      subTypes: [
        // 'Apartment',
        // 'Condominium',
        'Duplex',
        // 'Efficiency',
        'Income/MultiFamily',
        'Multi Family',
        'Quadruplex',
        // 'Residential',
        // 'Single Family Residence',
        'Special Purpose',
        // 'Townhouse',
        'Triplex'
      ]
    },
    {
      id: 4,
      type: "RNT Res Rental",
      subTypes: [
        'Apartment',
        'Single Family Residence',
        'Townhouse',
        'Villa'
      ]
    },
    {
      id: 5,
      type: "CLD Land-Comm/bus/Agr/Indust.",
      subTypes: [
        'Agriculture',
        'Industrial',
        'Mixed Use',
        'Special Purpose',
        // 'Commercial',
        // 'Income/MultiFamily',
        // 'Multi Family',
        // 'Office',
        // 'Residential',
        // 'Single Family Residence',
      ]
    },
    {
      id: 6,
      type: "COM Improved Comm/Indust.",
      subTypes: [
        'Flex Space',
        'Hotel/Motel',
        'Income/MultiFamily',
        'Industrial',
        'Mixed Use',
        'Office',
        'Restaurant/Entertainment',
        'Retail',
        'Special Purpose',
      ]
    },
    {
      id: 7,
      type: "BUS Business Opportunity",
      subTypes: [
        "Adult Congregate",
        "Anchored Center",
        "Apartments",
        "Automotive",
        "Bar/Lounge Only",
        "Bar/Tavern/Lounge",
        "Barber/Beauty",
        "Building Service",
        "Building Trades",
        "Building/Trade Service",
        "Business",
        "Child Care",
        "Church",
        "Commercial/Residential Income",
        "Condominium",
        "Consumer Service",
        "Convenience Store",
        "Education/School",
        "Farm",
        "Food & Beverage",
        "Franchise",
        "Gas Station",
        "Heavy Manufacturing",
        "Hotel/Motel",
        "Industrial",
        "Light Manufacturing",
        "Lounge",
        "Mall Enclosed",
        "Manufacturing",
        "Marina",
        "Marine",
        "Medical",
        "Medical Office",
        "Mini Warehouse",
        "Mobile/Rv Parks",
        "Mobile/Trailer Park",
        "Non-Profit",
        "Office Space",
        "Office/Warehouse Combination",
        "Other",
        "Other Type",
        "Personal/Consumer Service",
        "Professional",
        "Professional Service",
        "Residential-Multi-Family",
        "Residential-Single Family",
        "Restaurant",
        "Restaurant/Bar",
        "Retail",
        "Retail Space",
        "Service",
        "Shopping Center",
        "Showroom",
        "Special Purpose",
        "Storage",
        "Store/Warehouse Combination",
        "Strip Store",
        "Tavern/Bar",
        "Vending Industrial",
        "Warehouse Space",
        "Wholesale"
      ]
    },
  ]

  proptypeslistsale = [
    this.allpropertytypeslist[0],
    // this.allpropertytypeslist[3],
    this.allpropertytypeslist[5],
    this.allpropertytypeslist[2],
    this.allpropertytypeslist[6],
    this.allpropertytypeslist[4],
    this.allpropertytypeslist[1],
  ]
  proptypeslistlease = [
    // this.allpropertytypeslist[0],
    this.allpropertytypeslist[3],
    this.allpropertytypeslist[5],
    // this.allpropertytypeslist[2],
    // this.allpropertytypeslist[6],
    // this.allpropertytypeslist[4],
    // this.allpropertytypeslist[1],
  ]

  public searchQuery = {
    propertyTypes: {
      sale: this.proptypeslistsale,
      lease: this.proptypeslistlease
    },
    propertySubTypes: [
      "Single Family Residence",
      "Apartment",
      "Townhouse",
      "Multi Family",
      "Mobile Home",
      "Villa",
      "Lease",
      "Commercial",
      "Residential",
      "Efficiency",
      "Duplex",
      "Stock Cooperative",
      "Industrial",
      "Quadruplex",
      "Triplex",
      "Agriculture"
    ],
    price: 'ListPrice',
    lessThan: '.lt',
    greaterThan: '.gt',
    greaterEqualThan: '.gte',
    lessEqualThan: '.lte',
    notEqual: '.ne',
    beds: 'BedroomsTotal',
    baths: 'BathroomsFull',
    lotSizeSqft: 'LotSizeSquareFeet',
    lotSizeAcres: 'LotSizeAcres',
    onMarket: 'OnMarketDate',
    sqft: 'LivingArea',
    address: 'UnparsedAddress',
    propertyTypeLabel: 'PropertyType',
    storiesTotal: 'StoriesTotal',
    propertySubTypeLabel: 'PropertySubType',
    status: 'MlsStatus',
    listingKey: 'ListingKey',
    propertySubType: 'PropertySubType',
    lotSize: 'LotSizeSquareFeet',
    interiorFeatures: 'InteriorFeatures',
    appliances: 'Appliances',
    cooling: 'Cooling',
    poolFeatures: 'PoolFeatures',
    garageSpaces: 'GarageSpaces',
    view: 'View',
    zip: 'PostalCode',
    associationAmenities: 'AssociationAmenities',
    taxYear: 'TaxYear',
    folioNumber: 'ParcelNumber',
    petsAllowed: 'PetsAllowed',
    parkingFeatures: 'ParkingFeatures',
    heating: 'Heating',
    securityFeatures: 'SecurityFeatures',
    exteriorFeatures: 'ExteriorFeatures',
    leaseTerm: 'LeaseTerm',
    waterfrontYN: 'WaterfrontFeatures',
    lotFeatures: 'LotFeatures',
    year: 'YearBuilt',
    garage: 'GarageSpaces',
    associationFee: 'AssociationFee',
    maintenanceExpense: 'MaintenanceExpense',
    taxAnnualAmount: 'TaxAnnualAmount',
    remarks: 'PublicRemarks',
    listingID: 'ListingId',
    listAgentFullName: 'ListAgentFullName',
    listAgentSelfPhone: 'ListAgentSelfPhone',
    listAgentEmail: 'ListAgentEmail',
    listOfficeName: 'ListOfficeName',
    listOfficePhone: 'ListOfficePhone',
    listOfficeEmail: 'ListOfficeEmail',
    buyerAgencyCompensation: 'BuyerAgencyCompensation',
    privateRemarks: 'PrivateRemarks',
    middleOrJuniorSchool: 'MiddleOrJuniorSchool',
    elementarySchool: 'ElementarySchool',
    highSchool: 'HighSchool',
    walkScore: 'WalkScore',
    coords: 'Coordinates',
    leaseAmount: 'LeaseAmount',
    leaseFreq: 'LeaseAmountFrequency'
  }
  public search_types = [
    'Single Family/Condo (RE1/RE2)',
    'RLD Land/Boats/Docks',
    'RIN Res Income',
    'RNT Res Rental',
    'CLD Land-Comm/bus/Agr/Indust.',
    'COM Improved Comm/Indust.',
    'BUS Business Opportunity'
  ]


  //public baseurl = 'https://cosmore.ms3dev.it/public'
  public listing_base = 'https://listings.cosmore.com'
  public dev_base = "https://api-myco.ms3dev.it/public"
  public baseurl_ = 'https://api-myco.cosmore.com/public'
  public local_base = 'http://localhost'
  public base_node = 'http://localhost:3000'
  // public baseurl_ = this.dev_base
  // public baseurl_ = this.local_base
  public public_file = this.baseurl_ + '/storage/app/'
  public baseurl = this.baseurl_
  // public baseurl = this.baseurl_ + '/public'
  history = ''
  historyInitial = ''
  public is_posting = false
  public can_update_calendar = true
  public user: User = {}
  public current_transaction: Transaction = {documents: []}
  public current_property: Property = {}
  public transactionSteps = 7
  public offerSteps = 9
  public searchSteps = 6
  // same as from listing
  public toTransactionStep = 3
  onUser: EventEmitter<User> = new EventEmitter<User>()
  updateView: EventEmitter<any> = new EventEmitter<any>()
  escrows: { deposits?: EscrowDeposit[], disburs?: EscrowDisburs[] } = {}
  trasaction_log;
  cancel_reason = [
    "Third-party contingency not satisfied",
    "Buyer/Tenant exercised right to cancel",
    "Buyer/Tenant default",
    "Seller/Landlord default",
    "Other"
  ]
  close_file_types = {
    seller: [
      {id: 'listing_agreement', name: 'Listing agreement'},
      {id: 'hud', name: 'Settlement statement'},
      {id: 'any', name: 'Any additional documents'},
    ],
    buyer: [
      {id: 'condo', name: 'Condo approval'},
      {id: 'inspection_report', name: 'Inspection report or waiver'},
      {id: 'appraisal_report', name: 'Appraisal report or waiver'},
      {id: 'hud', name: 'Settlement statement'},
      {id: 'any', name: 'Any additional documents'},
    ],
  }
  mapsJSLoaded = false
  canc: CancelTransaction = {}
  currentFileToUpload: Array<{ file: File, type: string }> = []
  broker_mode: boolean = false
  notify_me = {
    all_day: [
      '5 minutes before',
      '10 minutes before',
      '15 minutes before',
      '1 hour before',
      '2 hours before',
      '1 day before',
      '2 days before',
      '1 week before'
    ],
    not_all_day: [
      'Same day',
      '1 day before',
      '2 days before',
      '1 week before'
    ]
  }
  emailErrorMessage: string = 'Email is not valid. Make sure you have entered a valid email.'
  user_areas = [
    'Aventura', 'Coconut Grove', 'Coral Gables', 'Doral',
    'Fort Lauderdale', 'Hallandale', 'Hallandale Beach', 'Hollywood', 'Kendall',
    'Miami',
    'Miami Beach',
    'North Miami',
    'North Miami Beach'
  ]

  updateRefine: EventEmitter<any> = new EventEmitter<any>()

  constructor(private instagram: Instagram, private nav: NavController, private router: Router, private _storage: StorageService, private platform: Platform, private http: HttpClient, public loadingController: LoadingController) {
    if (this.baseurl_ == this.dev_base) {
      this.listing_base = 'https://listings-myco.ms3dev.it'
    }
  }


  navigatingHistory(): boolean {
    return this.historyInitial != this.history
  }

  n_days(date) {
    return Math.floor((new Date().getTime() - Date.parse(date)) / 86400000)
  }

  getSaleLeaseLabel(t: Transaction) {
    return t.is_search == 1 ? 'purchase' : 'sale'
  }

  getSaleOrLeaseLabel(t: Transaction) {
    return t.is_search == 1 && t.type == 'sale' ? 'purchase' : t.type == 'sale' ? 'sale' : 'lease'
  }

  closingDateLabel(t: Transaction) {
    return (t.type == 'lease' ? 'Expected moving date' : 'Expected closing date')
  }

  checkEmail(email: string): boolean {
    if (!email || email == '') return false
    var check1 = email.split('@')
    if (check1.length != 2) {
      return false
    }
    var check2 = check1[1].split('.')
    if (check2.length != 2) {
      return false
    }
    return true
  }

  onChangePriceInput(value: string, price, input: IonInput) {
    let regexp = /.*[a-zA-Z].*/
    if (!value) {
      price = '';
    }
    if (!regexp.test(value)) {
      input.value = price
    } else {
      price = value;
    }
  }


  async toW9(t: Transaction) {
    await Browser.open({url: this.baseurl + '/document/view/' + t.w9});
  }

  hasExecutedContract(t: Transaction) {
    return t.documents.filter(e => e.type == 'contract').length > 0
  }

  iconBack(from = 0) {
    switch (from) {
      case 0:
        this.nav.navigateBack('/dashboard/settings')
        break
      case 1:
        this.nav.back()
        break
      case 2:
        this.nav.navigateBack('/dashboard/home')
    }
  }

  toPrice(s: string | number) {
    if (s == '') return 0
    if (typeof s == 'number' && isNaN(s)) return 0
    return typeof s == 'string' ? parseFloat(s.replace(/,/g, '')) : s
  }

  toStringPrice(s: string | number, withCents: boolean = true): string {
    if (s == null || s == '') return '-'
    var temp: string = (s + '').replace(/ /g, '').replace(/,/g, '')
    var cents = ''
    if (temp.indexOf('.') > -1) {
      cents = temp.split('.')[1]
      temp = temp.split('.')[0]
    }
    var reverse = ''
    for (var i = temp.length - 1; i >= 0; i--) {
      reverse += temp.charAt(i)
    }
    var res1 = ''
    var vcheck = 0
    for (var i = 0; i < reverse.length; i++) {
      res1 += reverse.charAt(i)
      if (vcheck % 2 == 0 && vcheck != 0 && i != reverse.length - 1) {
        res1 += ','
        vcheck = 0
      } else {
        vcheck++;
      }
    }
    var res = ''
    for (var i = res1.length - 1; i >= 0; i--) {
      res += res1.charAt(i)
    }
    return res + (withCents ? (cents != '' ? '.' + (cents) : '.00') : '')
  }

  imBroker(): boolean {
    return this.user && this.user.role == 'broker' && this.broker_mode
  }

  isDesktop(): boolean {
    return !(this.platform.is('cordova') || this.platform.is('capacitor'))
    return !this.platform.is('android') || !this.platform.is('ios')
  }

  getUserImage(u: User) {
    if (u.image == null) return '/assets/img/user-placeholder.png'
    return (this.baseurl.replace('public', '') + '/storage/app/' + u.image.path)
  }


  isClientUID(uid: string) {
    return uid.substr(0, 3) == 'cl_'
  }

  hasBuyer(t: Transaction) {
    if (!t || !t.clients) return false
    return t.clients.find(e => e.clients[0].type == 'buyer') != null
  }

  hasSeller(t: Transaction) {
    if (!t || !t.clients) return false
    return t.clients.find(e => e.clients[0].type == 'seller') != null
  }

  hasLetter(deposit: EscrowDeposit = null, transaction: Transaction): boolean {
    if (deposit == null) return transaction.deposits.length > 0 && transaction.deposits.filter(e => e.has_letter == 0).length == 0
    return deposit.has_letter == 1
  }

  pendingDisburs(dis: EscrowDisburs[]): boolean {
    return dis.length > 0 && dis.filter(e => e.disbursed == 0).length > 0
  }

  getRemainingBalance(d: EscrowDisburs = null, created: EscrowDisburs[] = []): number {
    if (this.escrows.deposits && this.escrows.disburs) {
      var deposit_sum = this.getDepositSum()
      var disburs_sum = this.escrows.disburs.reduce((partialSum, a) => partialSum + Number(a.amount.replace(/,/g, '')), 0);
      if (created)
        disburs_sum += created.reduce((partialSum, a) => partialSum + Number(this.toPrice(a.amount)), 0);
      var res = deposit_sum - disburs_sum - (d == null ? 0 : Number((this.toPrice(d && d.amount)) ?? 0))
      return Number(res.toFixed(2))
    }
    return 0
  }

  getDepositSum(depo: EscrowDeposit[] = null) {
    if (depo != null) {
      return depo.reduce((partialSum, a) => partialSum + Number(a.amount.replace(/,/g, '')), 0);
    }
    return this.escrows.deposits.reduce((partialSum, a) => partialSum + Number(a.amount.replace(/,/g, '')), 0);
  }

  async dismissLoading() {
    setTimeout(async () => {
      await this.loadingController.dismiss()
    }, 500)
  }

  async presentLoading(message: string = 'Please wait...') {
    const loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: message,
    });
    await loading.present();

    const {role, data} = await loading.onDidDismiss();
    console.log('Loading dismissed!');
  }

  startTransactionFromProperty(p: Property) {
    this.post(APIRoutes.create_transaction, {
      type: p.sale_lease,
      puid: p.uid,
      client: p.owner_uid
    }, (res) => {
      this.router.navigate([PageRoutes.add_transaction.addSlash() + '/' + res.uid], {
        queryParams: {
          fromListing: true
        }
      })
    })
  }

  startTransactionFromMLSProperty(puid, offer, uid) {
    this.post(APIRoutes.create_transaction, {
      puid: puid,
      t_uid: uid,
      is_offer: offer,
      is_search: 0,
    }, async (res) => {
      await this._storage.remove(Keys.temp_data_for_mls_conversion)
      await this.router.navigate([PageRoutes.sale_lease.addSlash() + '/' + res.uid], {
        queryParams: {
          fromListing: true
        }
      })
    })
  }

  delete(to: APIRoutes, data, callback) {
    return this.http.delete(this.baseurl + to, data).subscribe(res => {
      callback(res)
    }, error => {
      alert(this.getErrorString(error))
    })
  }

  post(to: APIRoutes, data, callback, onProgress = null, throwError = true) {
    this.is_posting = true
    //this.presentLoading()
    var options = {}
    if (onProgress) {
      options = {
        reportProgress: true,
        observe: 'events'
      }
    }
    return this.http.post(this.baseurl + to, data, options).subscribe(res => {
      if (onProgress) {
        if (res['type'] === HttpEventType.Response) {
          this.is_posting = false
          callback(res, res['error'] ? res['error'] : null)
        }
        if (res['type'] === HttpEventType.UploadProgress) {
          const percentDone = Math.round(100 * res['loaded'] / res['total']);
          onProgress(percentDone)
        }
      } else {
        this.is_posting = false
        //this.loadingController.dismiss()
        callback(res, res['error'] ? res['error'] : null)
      }
    }, error => {
      // alert(JSON.stringify(error))
      this.is_posting = false
      this.dismissLoading()
      if (!throwError) return
      if (error.status == 401) {
        this.logout()
      } else if (error.status == 504 || error.status == 0)
        alert("Network error, check your internet connection and try again. If the problem persists, restart the application.")
      else if (error.error) {
        alert(this.getErrorString(error))
      }
      console.log('error', error)
    })
  }

  getErrorString(error) {
    var error_string = "";
    if (error.error.message) {
      error_string = error.error.message
    } else {
      var keys = Object.keys(error.error)
      keys.forEach(e => {
        error_string += error.error[e] + '\n';
      })
    }
    return error_string
  }

  forkGet(routes: string[], callback) {
    console.log("Fork", routes)
    forkJoin(routes.map(e => this.http.get(this.baseurl + e))).subscribe(res => {
      callback(res)
    })
  }

  async logout() {
    this.user = null
    await this._storage.remove(Keys.impersonating)
    await this._storage.remove(Keys.broker_mode)
    await this._storage.remove(Keys.api_token_key)
    await this._storage.remove(Keys.username)
    // await this._storage.removeAll()
    this.broker_mode = false
    this.router.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
  }

  get(to: APIRoutes, callback, withLoading = true, throwError = true) {
    //if (withLoading) await this.presentLoading()
    var call = this.http.get(this.baseurl + to)
    return call.subscribe(res => {
      /*if (withLoading) {
        setTimeout(() => {
          this.loadingController.dismiss()
        }, 300)
      }*/
      if (to == APIRoutes.me) {
        this.user = res as User
        if (typeof this.user.area === "string") {
          this.user.area = this.user.area.split(',')
        }
        this.onUser.emit(res as User)
      }
      if ((to as string).split('/')[1] == 'transaction') {
        this.current_transaction = res
      }
      callback(res, res['error'] ? res['error'] : null)
    }, error => {
      console.log(error)
      this.dismissLoading()
      if (!throwError) return
      if (error.status == 401) {
        this.logout()
      }
      else if (error.status == 504 || error.status == 0)
        alert("Network error, check your internet connection and try again. If the problem persists, restart the application.")
      else {
        callback(error.error)
      }
      console.log('error', error)
    })
    // return call
  }

  isValidVal(val) {
    if (val == '0') return true
    if (val == "") return false
    if (val == null) return false
    if (val == undefined) return false
    //if (isNaN(Number(val))) return false
    return true
  }

  buyerLabel(t: Transaction, lower = false) {
    if (!t) return ''
    var res = t.type == 'sale' ? 'Buyer' : t.type == 'lease' ? 'Tenant' : ''
    return lower ? res.toLowerCase() : res
  }

  sellerLabel(t: Transaction, lower = false) {
    if (!t) return ''
    var res = t.type == 'sale' ? 'Seller' : t.type == 'lease' ? 'Landlord' : ''
    return lower ? res.toLowerCase() : res
  }

  getClientsString(t: Transaction) {
    var res = []
    t.clients.forEach((e) => {
      res.push(e.clients[0].first_name + ' ' + e.clients[0].last_name)
    })
    return res.join(', ')
  }


  toNot(n: string, uid, transaction_id, client_id, lead_id, not: Notifications, fromNot: boolean = false, deadline_id = null, user_id = null) {
    console.log(n)
    if (uid)
      this.post(APIRoutes.set_notification_visited + '/' + uid, {}, () => {
        if (not != null) {
          not.visited = 1
        }
      })
    switch (n) {
      case 'broker_alert':
        this.router.navigate(['/messages'])
        break
      case 'dead':
        this.router.navigate([PageRoutes.deadline_detail.addSlash() + '/' + deadline_id])
        break
      case 'new_lead':
        this.router.navigate([PageRoutes.client.addSlash() + '/' + lead_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'escrow_letter':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'escrow_letter_requested':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'escrow_disburs_requested':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'commission_requested_closure':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'disburs_confirmed':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'action_from_broker':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'action_completed':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'commission_confirmed':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'details'
          }
        })
        break
      case 'message_from_broker_with_files':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'documents'
          }
        })
        break
      case 'doc_deletion':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'documents'
          }
        })
        break
      case 'client_documents':
        this.router.navigate([PageRoutes.client.addSlash() + '/' + client_id], {
          queryParams: {
            selectedTab: 'documents'
          }
        })
        break
      case 'client_documents_with_transaction':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id], {
          queryParams: {
            selectedTab: 'documents'
          }
        })
        break
      case 'message_from_agent':
        this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id])
        break
      case 'file_del_approved':
        this.router.navigate([PageRoutes.notification_detail.addSlash() + '/' + uid])
        break
      case 'file_del_rej':
        this.router.navigate([PageRoutes.notification_detail.addSlash() + '/' + uid])
        break
      case 'user_registered':
        this.router.navigate([PageRoutes.agent_detail.addSlash() + '/' + user_id])
        break
      default:
        if (transaction_id)
          this.router.navigate([PageRoutes.transaction.addSlash() + '/' + transaction_id])
        break
    }
  }

  schools(callback: any) {

  }

  loadMapsJS(callback) {
    this.http.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyBxXqgHzStuy4RyyFEnqGuIgoOlRv78ELA&libraries=places,drawing,geometry', 'callback').subscribe(res => {
      this.mapsJSLoaded = true
      callback(res)
    })
  }

  setCommissionValues(values: any, transaction: Transaction) {
    if (values.get(0) && !values.get(0).value)
      values.get(0).writeValue(transaction.price)
    if (transaction.type == 'lease') {
      if (values.get(1) && !values.get(1).value)
        values.get(1).writeValue(transaction.montly_amount)
      if (values.get(2) && !values.get(2).value)
        values.get(2).writeValue(transaction.full_commission)
      if (values.get(3) && !values.get(3).value)
        values.get(3).writeValue(transaction.commission)
      if (values.get(4) && !values.get(4).value)
        values.get(4).writeValue(transaction.agent_commission)
      if (values.get(5) && !values.get(5).value)
        values.get(5).writeValue(transaction.counter_broker_commission)
    } else {
      if (values.get(1) && !values.get(1).value)
        values.get(1).writeValue(transaction.full_commission)
      if (values.get(2) && !values.get(2).value)
        values.get(2).writeValue(transaction.commission)
      if (values.get(3) && !values.get(3).value)
        values.get(3).writeValue(transaction.agent_commission)
      if (values.get(4) && !values.get(4).value)
        values.get(4).writeValue(transaction.counter_broker_commission)
    }
    // if (this.hasSeller(transaction) && transaction.type == 'sale') {
    //   if (values.get(1) && !values.get(1).value)
    //     values.get(1).writeValue(transaction.full_commission)
    //   if (values.get(2) && !values.get(2).value)
    //     values.get(2).writeValue(transaction.commission)
    //   if (values.get(3) && !values.get(3).value)
    //     values.get(3).writeValue(transaction.agent_commission)
    //   if (values.get(4) && !values.get(4).value)
    //     values.get(4).writeValue(transaction.counter_broker_commission)
    // } else if (!this.hasSeller(transaction) && transaction.type == 'sale') {
    //   // if (values.get(1) && !values.get(1).value)
    //   //   values.get(1).writeValue(transaction.full_commission)
    //   if (values.get(1) && !values.get(1).value)
    //     values.get(1).writeValue(transaction.commission)
    //   if (values.get(2) && !values.get(2).value)
    //     values.get(2).writeValue(transaction.agent_commission)
    //   if (values.get(3) && !values.get(3).value)
    //     values.get(3).writeValue(transaction.counter_broker_commission)
    // }
  }

  async viewDoc(uid: string) {
    await Browser.open({url: this.baseurl + '/document/view/' + uid});
  }

  initMonthChanged(callback) {
    var tempInt = setInterval(() => {
      if (document
        .querySelector('ion-datetime')) {
        const observer = new MutationObserver((mutationRecords) => {
          console.log(mutationRecords)
          if (mutationRecords) {
            window.dispatchEvent(new CustomEvent('datetimeMonthDidChange', {detail: mutationRecords}));
          }
        });

        observer.observe(
          document
            .querySelector('ion-datetime')
            .shadowRoot.querySelector('.calendar-body'),
          {
            subtree: true,
            childList: true,
          }
        );
        console.log('initMonthChanged')
        window.addEventListener('datetimeMonthDidChange', (ev) => {
          console.log('datetimeMonthDidChange')
          // get month-targets from all mutations
          const mutatedMonths = (ev as CustomEvent<MutationRecord[]>).detail?.filter((n) =>
            (n.target as Element).classList.contains('calendar-month-grid')
          );

          // find unique month/years
          let months: { month: number; year: number }[] = Array.from(mutatedMonths).reduce((a, monthMutation) => {
            const thisMonth = Array.from(monthMutation.target?.childNodes).find((n) => (n as any)?.dataset?.day === '1');
            const {month, year} = (thisMonth as any).dataset;
            if (a.find((n) => n.month === month && n.year === year)) return a;
            return [...a, {month, year}];
          }, []);

          // we search the middle month
          months = months.sort((a, b) => (a.year === b.year ? a.month - b.month : a.year - b.year));
          const activeMonth = months.length > 2 ? months[1] : null;
          console.log('Active month', activeMonth)
          callback(activeMonth)
        });
        clearInterval(tempInt)
      }
    }, 10)

  }

  buildRefineQuery(t: Transaction) {
    var query = t.search_query ? t.search_query.split('&') : []
    if (typeof t.search_amenities == 'string') {
      t.search_amenities = t.search_amenities.split(',')
    }
    if (query.findIndex(e => e.indexOf('City=') > -1) > -1)
      query.splice(query.findIndex(e => e.indexOf('City=') > -1), 1)
    if (query.findIndex(e => e.indexOf('Furnished=') > -1) > -1)
      query.splice(query.findIndex(e => e.indexOf('Furnished=') > -1), 1)
    if (query.findIndex(e => e.indexOf('AssociationAmenities=') > -1) > -1)
      query.splice(query.findIndex(e => e.indexOf('AssociationAmenities=') > -1), 1)
    console.log(query)
    if (t.search_city) {
      query.push('City=' + encodeURIComponent(t.search_city))
    }
    if (t.search_furnished) {
      query.push('Furnished=' + t.search_furnished)
    }
    if (t.search_amenities && (t.search_amenities as string[]).length > 0) {
      query.push('AssociationAmenities=' + (t.search_amenities as string[]).map(e => encodeURIComponent(e)).join(','))
      t.search_amenities = (t.search_amenities as string[]).join(',')
    }
    return query;
  }

  wasOffer(t: Transaction) {
    return t.offer_sent_on != null
  }

  wasSearch(t: Transaction) {
    return t.search_property_type != null
  }

  isContract(t: Transaction) {
    return t.effective_date != null
  }

  toInstagram(uid: string, data: any, key: string) {
    // this.instagram.isInstalled().then(res => {
    // if (res) {
    this.http.get(this.listing_base + '/generate/image/' + uid + '/' + key + '/instagram/' + data.style + '/' + data.status).subscribe(res__ => {
      this.dismissLoading()
      this.instagram.share(res__['data'], '')
        .then(() => {

        })
        .catch((error: any) => {
          this.dismissLoading()
          // alert("There was an error with Instagram. Try again.")
        })
    })
    //   } else {
    //     this.dismissLoading()
    //     alert("It seems that Instagram is not installed on your device!")
    //   }
    // });
  }

}

export class APIRoutes {
  public static pre_listings = '/pre_listings'
  public static delete_pre_listing_image = '/delete_pre_listing_image'
  public static add_share = '/add_share'
  public static pre_listing = '/pre_listing'
  public static tiers = '/tiers'
  public static agree_pre_listing = '/agree_pre_listing'
  public static pre_listing_message = '/pre_listing_message'
  public static send_files_to_client = '/send_files_to_client'
  public static tier = '/tier'
  public static new_devs = '/new_devs'
  public static new_dev = '/new_dev'
  public static safe_delete_transaction = '/safe_delete_transaction'
  public static mls_sub_types = '/mls_sub_types'
  public static node_mls = '/query'
  public static sedi = '/sedi'
  // public static tiers = '/sedi'
  public static sede = '/sede'
  public static mls_property = '/mls_property'
  public static schools = '/schools'
  public static save_sort_query = '/save_sort_query'
  public static mls_preferiti = '/mls_preferiti'
  public static search_display_type = '/search_display_type'
  public static search_specs = '/search_specs'
  public static search_property_type = '/search_property_type'
  public static is_registration_active = '/registration_active'
  public static office_policy = '/office_policy'
  public static login = "/login"
  public static me = '/me'
  public static change_password = '/change_password'
  public static save_phone = '/save_phone'
  public static add_license = '/add_license'
  public static update_login_flag = '/update_login_flag'
  public static upload_transaction_file = '/upload_transaction_file'
  public static get_transaction_file = "/get_transaction_file"
  public static create_transaction = "/create_transaction"
  public static update_step = "/update_step"
  public static update_last_path = "/update_last_path"
  public static update_type = "/update_type"
  public static create_client = "/client"
  public static check_client_existence = "/check_client_existence"
  public static clients = "/clients"
  public static all_clients_no_leads = "/all_clients_no_leads"
  public static all_leads = "/all_leads"
  public static delete_account = "/delete_account"
  public static transaction_client = "/transaction_client"
  public static property = "/property"
  public static property_spec = "/property_spec"
  public static update_property_more = "/update_property_more"
  public static update_property_data = "/update_property_data"
  public static update_property_owner_name = "/update_property_owner_name"
  public static update_transaction_referral = "/update_transaction_referral"
  public static update_transaction_last_data = "/update_transaction_last_data"
  public static transaction = "/transaction"
  public static save_transaction = "/save_transaction"
  public static my_transactions = "/my_transactions"
  public static get_property_from_transaction = "/get_property_from_transaction"
  public static get_client_properties = "/get_client_properties"
  public static update_transaction_property = "/update_transaction_property"
  public static agents = "/agents"
  public static delete_transaction = "/transaction/delete"
  public static delete_transaction_file = "/transaction/delete_file"
  public static add_note = "/add_note"
  public static log = "/log"
  public static escrow_deposit = "/escrow_deposit"
  public static escrow_disburs = "/escrow_disburs"
  public static escrows = "/escrows"
  public static delete_disburs = "/delete_disburs"
  public static delete_escrow = "/delete_escrow"
  public static delete_escrow_deposit = "/delete_escrow_deposit"
  public static add_deadline = "/add_deadline"
  public static deadline_presets = "/deadline_presets"
  public static rename_file = "/rename_file"
  public static send_message_to_client = "/send_message_to_client"
  public static update_status_with_cancel = "/update_status_with_cancel"
  public static contact = "/contact"
  public static close_cancel = "/close_cancel"
  public static amend = "/amend"
  public static save_offer_more = "/save_offer_more"
  public static update_contract_offer = "/update_contract_offer"
  public static add_assigned_file = "/add_assigned_file"
  public static deadlines = "/deadlines"
  public static list_docs = "/list_docs"
  public static request_deletion = "/request_deletion"
  public static deadline = "/deadline"
  public static delete_deadline = "/delete_deadline"
  public static all_clients = "/all_clients"
  public static client = "/client"
  public static listing_search = "/listing_search"
  public static client_log = "/client_log"
  public static client_transactions = "/client_transactions"
  public static is_document_deletable = "/is_document_deletable"
  public static cancel_request_deletion = "/cancel_request_deletion"
  public static escrow_list_broker = "/escrow_list_broker"
  public static get_escrow_letter = "/get_escrow_letter"
  public static confirm_escrow_letter = "/confirm_escrow_letter"
  public static get_disburs = "/get_disburs"
  public static confirm_disburs = "/confirm_disburs"
  public static pending_disbursement_broker = "/pending_disbursement_broker"
  public static broker_message = "/broker_message"
  public static confirm_disburs_close_cancel = "/confirm_disburs_close_cancel"
  public static get_broker_message = "/get_broker_message"
  public static save_player_id = "/save_player_id"
  public static reject_document = "/reject_document"
  public static tag = "/tag"
  public static get_message_to_client = "/get_message_to_client"
  public static assign_client_documents_to_agent_message = "/assign_client_documents_to_agent_message"
  public static get_note = "/get_note"
  public static get_deposit = "/get_deposit"
  public static get_disburs_overview = "/get_disburs_overview"
  public static delete_client = "/delete_client"
  public static can_delete_client = "/can_delete_client"
  public static check_google_sync = "/check_google_sync"
  public static notifications = "/notifications"
  public static set_notification_visited = "/set_notification_visited"
  public static notification = "/notification"
  public static register = "/register"
  public static register_public = "/register_public"
  public static agent = "/agent"
  public static assign_client = "/assign_client"
  public static unassign_client = "/unassign_client"
  public static block_agent = "/block_agent"
  public static unblock_agent = "/unblock_agent"
  public static password_reset = "/password_reset"
  public static change_notification = "/change_notification"
  public static update_sale_lease = "/update_sale_lease"
  public static update_prop_type = "/update_prop_type"
  public static update_listing_agreement = "/update_listing_agreement"
  public static update_prop_more_info = "/update_prop_more_info"
  public static update_beds_baths = "/update_beds_baths"
  public static update_user_data = "/update_user_data"
  public static clients_from_transaction = '/clients_from_transaction'
  public static license = '/license'
  public static remove_license = '/remove_license'
  public static broker_alert = '/broker_alert'
  public static broker_alerts = '/broker_alerts'
  public static mls = '/mls'
  public static send_search_to_client = '/send_search_to_client'
  public static choose_from_mls = '/choose_from_mls'
  public static save_notes = '/save_notes'
  public static offers = '/offers'
}


class PageRoute {
  route: string;

  constructor(s: string) {
    this.route = s
  }

  public addSlash(): string {
    return '/' + this.route
  }
}

export class PageRoutes {
  public static search_specs = new PageRoute('search-specs')
  public static search_property_detail = new PageRoute('search-property-detail')
  public static search_end = new PageRoute('search-end')
  public static search_refine = new PageRoute('search-refine')
  public static search_results = new PageRoute('search-results')
  public static search_display_type = new PageRoute('search-result-display-type')
  public static search_property_type = new PageRoute('search-property-type')
  public static start_new_transaction = new PageRoute('start-new-transaction')
  public static dashboard: PageRoute = new PageRoute('dashboard')
  public static broker_alert: PageRoute = new PageRoute('broker-alert')
  public static password: PageRoute = new PageRoute('password')
  public static login: PageRoute = new PageRoute('login')
  public static phone: PageRoute = new PageRoute('phone')
  public static generic_list: PageRoute = new PageRoute('generic-list')
  public static licenses: PageRoute = new PageRoute('licenses')
  public static add_license: PageRoute = new PageRoute('add-license')
  public static message_page: PageRoute = new PageRoute('message-page')
  public static forgot_password: PageRoute = new PageRoute('forgot-password')
  public static add_transaction: PageRoute = new PageRoute('add-transaction')
  public static upload_transaction_file: PageRoute = new PageRoute('upload-transaction-file')
  public static sale_lease: PageRoute = new PageRoute('sale-lease')
  public static client_type: PageRoute = new PageRoute('client-type')
  public static clients: PageRoute = new PageRoute('clients')
  public static add_client: PageRoute = new PageRoute('add-client')
  public static property_type: PageRoute = new PageRoute('property-type')
  public static property_more: PageRoute = new PageRoute('property-more')
  public static property_data: PageRoute = new PageRoute('property-data')
  public static property_owner_name: PageRoute = new PageRoute('property-owner-name')
  public static transaction_referral: PageRoute = new PageRoute('transaction-referral')
  public static transaction_last_data: PageRoute = new PageRoute('transaction-data')
  public static summary: PageRoute = new PageRoute('summary')
  public static properties: PageRoute = new PageRoute('properties')
  public static broker_dashboard: PageRoute = new PageRoute('broker-dashboard')
  public static agents: PageRoute = new PageRoute('agents')
  public static transaction: PageRoute = new PageRoute('transaction')
  public static add_note: PageRoute = new PageRoute('add-note')
  public static escrow_deposit: PageRoute = new PageRoute('escrow-deposit')
  public static escrow_disburs: PageRoute = new PageRoute('escrow-disburs')
  public static add_disburs: PageRoute = new PageRoute('add-disburs')
  public static add_deadline: PageRoute = new PageRoute('add-deadline')
  public static add_deadline_form: PageRoute = new PageRoute('add-deadline-form')
  public static message_client: PageRoute = new PageRoute('message-client')
  public static message_client_preview: PageRoute = new PageRoute('message-client-preview')
  public static cancel_reason: PageRoute = new PageRoute('cancel-reason')
  public static cancel_notice: PageRoute = new PageRoute('cancel-notice')
  public static cancel_summary: PageRoute = new PageRoute('cancel-summary')
  public static cancellation_disbursement: PageRoute = new PageRoute('cancellation-disbursement')
  public static close_files: PageRoute = new PageRoute('close-files')
  public static close_files_upload: PageRoute = new PageRoute('close-files-upload')
  public static close_confirm: PageRoute = new PageRoute('close-confirm')
  public static closing_agent: PageRoute = new PageRoute('closing-agent')
  public static close_summary: PageRoute = new PageRoute('close-summary')
  public static close_amend: PageRoute = new PageRoute('close-amend')
  public static offer_more: PageRoute = new PageRoute('offer-more')
  public static amend_offer_document: PageRoute = new PageRoute('amend-offer-document')
  public static amend_offer_summary: PageRoute = new PageRoute('amend-offer-summary')
  public static document_list: PageRoute = new PageRoute('document-list')
  public static client: PageRoute = new PageRoute('client')
  public static contract_type: PageRoute = new PageRoute('contract-type')
  public static listing_type: PageRoute = new PageRoute('listing-type')
  public static listing_type_more: PageRoute = new PageRoute('listing-type-more')
  public static area_budget: PageRoute = new PageRoute('area-budget')
  public static listing_details: PageRoute = new PageRoute('listing-details')
  public static listing_notes: PageRoute = new PageRoute('listing-notes')
  public static agreement_document: PageRoute = new PageRoute('agreement-document')
  public static property_more_info: PageRoute = new PageRoute('property-more-info')
  public static property_last_info: PageRoute = new PageRoute('property-last-info')
  public static deadline_detail: PageRoute = new PageRoute('deadline-detail')
  public static upload_escrow_letter: PageRoute = new PageRoute('upload-escrow-letter')
  public static upload_letter: PageRoute = new PageRoute('upload-letter')
  public static escrow_letter_summary: PageRoute = new PageRoute('escrow-letter-summary')
  public static disburs_details: PageRoute = new PageRoute('disburs-details')
  // this is for tab overview
  public static disburs_detail: PageRoute = new PageRoute('disburs-detail')
  //
  public static request_actions: PageRoute = new PageRoute('request-actions')
  public static message_agent: PageRoute = new PageRoute('message-agent')
  public static upload_agent_files: PageRoute = new PageRoute('upload-agent-files')
  public static agent_files_summary: PageRoute = new PageRoute('agent-files-summary')
  public static lead_details: PageRoute = new PageRoute('lead-details')
  public static client_to_agent_files: PageRoute = new PageRoute('client-to-agent-files')
  public static note_detail: PageRoute = new PageRoute('note-detail')
  public static deposit_detail: PageRoute = new PageRoute('deposit-detail')
  public static message_to_client_detail: PageRoute = new PageRoute('message-to-client-detail')
  public static listing_detail: PageRoute = new PageRoute('listing-detail')
  public static property_detail: PageRoute = new PageRoute('property-detail')
  public static notifications: PageRoute = new PageRoute('notifications')
  public static notification_detail: PageRoute = new PageRoute('notification-detail')
  public static agent_detail: PageRoute = new PageRoute('agent-detail')
  public static deadline_search: PageRoute = new PageRoute('deadline-search')
  public static user_data: PageRoute = new PageRoute('user-data')
}
