export class Keys {
  public static api_token_key = "api_token_key"
  public static remember_me = "remember_me"
  public static username = "username"
  public static is_biometric_active = "is_biometric_active"
  public static current_transaction_uid = "current_transaction_uid"
  public static temp_deadlines = "temp_deadlines"
  public static temp_client_message = "temp_client_message"
  public static temp_cancellation_transaction = "temp_cancellation_transaction"
  public static closing_agent = "closing_agent"
  public static temp_transaction = "temp_transaction"
  public static temp_amend_documents = "temp_amend_documents"
  public static temp_amend_offer = "temp_amend_offer"
  public static temp_listing_search = "temp_listing_search"
  public static temp_property_from_client = "temp_property_from_client"
  public static just_created_transaction = "just_created_transaction"
  public static just_created_first_deadlines = "just_created_first_deadlines"
  public static temp_disburs_list = "temp_disburs_list"
  public static broker_mode = "broker_mode"
  public static impersonating = "impersonating"
  public static mobile_push = "mobile_push"
  public static web_push = "web_push"
  public static temp_files_to_agent = "temp_files_to_agent"
  public static temp_client = "temp_client"
  public static notification_received = "notification_received"
  public static temp_edit_property = "temp_edit_property"
  public static temp_data_for_mls_conversion = 'temp_data_for_mls_conversion'
  public static needs_transaction_update = 'needs_transaction_update'
  public static temp_pre_listing = 'temp_pre_listing'
}
