import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {APIRoutes, ApiService, PageRoutes} from "../services/api.service";
import {HttpClient} from "@angular/common/http";
import {Roles} from "../common/roles";

@Injectable({
  providedIn: 'root'
})
export class BrokerGuardGuard implements CanActivate {
  constructor(private api: ApiService, private nav: Router, private http: HttpClient) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /*if (this.api.user.id) {
      if (this.api.user.role != Roles.broker) {
        this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
        return false
      }
    }*/
    if (this.api.user.uid && this.api.imBroker()) return true
    return this.http.get(this.api.baseurl + APIRoutes.me).toPromise().then(res => {
      if (res['role'] != Roles.broker) {
        this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
        return false
      }
      this.api.user = res
      return true
    }, error => {
      this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
      return false
    });
  }

}
