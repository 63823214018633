import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {from, Observable} from "rxjs";
import {StorageService} from "./storage.service";
import {Keys} from "../common/keys";
import {switchMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {
  constructor(private _storage: StorageService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler)
    : Observable<HttpEvent<any>> {
    return from(this._storage.get(Keys.api_token_key)).pipe(
      switchMap((token) => {
        if (token) {
          var h = req.headers.set('api-token', token)
          //
          //h = h.set('impersonating', 'ag_62f1196f8194b')
          //
          const authReq = req.clone({
            headers: h
          });
          return from(this._storage.get(Keys.impersonating)).pipe(
            switchMap(i => {
              if (i) {
                return next.handle(authReq.clone({
                  headers: authReq.headers.set('impersonating', i.uid)
                }))
              }
              return next.handle(authReq)
            })
          )
        }
        return next.handle(req)
      })
    )
  }
}
