import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {FingerprintAIO} from "@ionic-native/fingerprint-aio/ngx";
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {IonicStorageModule} from "@ionic/storage-angular";
import {InterceptorService} from "./services/interceptor.service";
import {LocalNotificationsWeb} from "@capacitor/local-notifications/dist/esm/web";
import {LocalNotifications} from "@capacitor/local-notifications/";
import {TabDetailsPageModule} from "./pages/transaction-overview/tab-details/tab-details.module";
import {LocalizeInputDirective} from './localize-input.directive';
import {ToStringPricePipe} from './to-string-price.pipe';
import {CurrencyInputDirectiveDirective} from './currency-input-directive.directive';
import {ScrollingModule} from "@angular/cdk/scrolling";
import {SharedModule} from "./shared/shared.module";
import {Instagram} from "@awesome-cordova-plugins/instagram/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  exports: [

  ],
  imports: [
    IonicStorageModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot({swipeBackEnabled: false}),
    AppRoutingModule,
    HttpClientJsonpModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })],
  providers: [
    Instagram,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    FingerprintAIO,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    }],
  bootstrap: [AppComponent],
})
export class AppModule {
}
