import {Component, OnInit, Renderer2} from '@angular/core';
import {APIRoutes, ApiService, PageRoutes} from "./services/api.service";
import {Router} from "@angular/router";
import {StorageService} from "./services/storage.service";
import {Keys} from "./common/keys";
import {User} from "./model/user";
import OneSignal from "onesignal-cordova-plugin";
import {Platform} from "@ionic/angular";
import {ScriptService} from "./services/script.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages = [
    {title: 'ion-item class="empty"><', url: PageRoutes.add_transaction.addSlash(), icon: 'mail'},
    {title: 'Logout', url: PageRoutes.add_transaction.addSlash(), icon: 'mail'},
  ];
  user: User = {}

  constructor(private renderer: Renderer2, private ls: ScriptService, private platform: Platform, private api: ApiService, private router: Router, private _storage: StorageService) {
    this.api.onUser.subscribe(res => {
      this.user = res
    })
  }

  async ngOnInit() {


    this.api.broker_mode = await this._storage.get(Keys.broker_mode) || false

    if (!this.api.isDesktop()) {
      this.OneSignalInit()
    } else {
      const scriptElement = this.ls.loadJsScript(this.renderer, 'https://cdn.onesignal.com/sdks/OneSignalSDK.js');
      scriptElement.onload = () => {
        var w = (window as any)
        w.OneSignal = w.OneSignal || [];
        w.OneSignal.push(() => {
          w.OneSignal.init({
            appId: this.api.baseurl_ != this.api.dev_base ? "4e9b4d59-8d41-4e0b-9ea2-cd2397a0b038" : "1cd6d69e-db45-4d91-8103-62c0f2a9be80", // prod
            // appId: "1cd6d69e-db45-4d91-8103-62c0f2a9be80", // dev
            // safari_web_id: "web.onesignal.auto.32866db8-e87d-48d6-8bf4-a7b54cca5ee4",
            // subdomainName: "myco-cosmore",
          });
          w.OneSignal.showSlidedownPrompt();
          w.OneSignal.on('notificationClickHandlerAction', (res) => {
            console.log(res)
          })
          w.OneSignal.on('notificationPermissionChange', (permissionChange) => {
            var currentPermission = permissionChange.to;
            console.log('New permission state:', currentPermission);
            w.OneSignal.getUserId().then(async (userId) => {
              console.log("OneSignal User ID:", userId);
              await this._storage.set(Keys.web_push, userId)
              // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
              this.api.post(APIRoutes.save_player_id, {
                type: 'web',
                player_id: userId
              }, (res) => {
              }, null, false)
            });
          });
          w.OneSignal.getUserId().then(async (userId) => {
            console.log("OneSignal User ID:", userId);
            await this._storage.set(Keys.web_push, userId)
            // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
            this.api.post(APIRoutes.save_player_id, {
              type: 'web',
              player_id: userId
            }, (res) => {
            }, null, false)

          });
        });
      }
    }
    if (!this.api.isDesktop()) {
      this.router.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
      return
    }
  }

  agents() {
    this.router.navigate([PageRoutes.agents.addSlash()])
  }

  createTransaction() {
    this.api.post(APIRoutes.create_transaction, {}, (res) => {
      this.router.navigate([PageRoutes.add_transaction.addSlash() + '/' + res.uid])
    })
  }

  async logout() {
    // await this._storage.removeAll()
    // this.router.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
    await this.api.logout()
  }

  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE
    // OneSignal.setLogLevel(6, 0);
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    //4e9b4d59-8d41-4e0b-9ea2-cd2397a0b038
    if (this.api.dev_base == this.api.baseurl_) {
      OneSignal.setAppId("1cd6d69e-db45-4d91-8103-62c0f2a9be80"); // dev
    } else {
      OneSignal.setAppId("4e9b4d59-8d41-4e0b-9ea2-cd2397a0b038"); // prod
    }
    OneSignal.setNotificationWillShowInForegroundHandler(function (notificationReceivedEvent) {
      notificationReceivedEvent.complete(notificationReceivedEvent.getNotification());
    });
    OneSignal.setNotificationOpenedHandler(async (jsonData) => {
      //alert((jsonData.notification.additionalData['type']))
      var data = jsonData.notification.additionalData
      await this._storage.set(Keys.notification_received, data)
      //this.api.toNot(data['type'], data['uid'], data['transaction_id'], data['client_id'], null)
      console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
    OneSignal.addSubscriptionObserver(async (state) => {
      console.log("Subscribed for OneSignal push notifications! :: ID " + state.to.userId);
      await this._storage.set(Keys.mobile_push, state.to.userId)
      this.api.post(APIRoutes.save_player_id, {
        type: 'mobile',
        player_id: state.to.userId
      }, (res) => {
      })
    });
    // iOS - Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
    OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
      console.log("User accepted notifications: " + accepted);
    });
  }

}
