import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {APIRoutes, ApiService, PageRoutes} from "../services/api.service";
import {HttpClient} from "@angular/common/http";
import {Roles} from "../common/roles";
import {User} from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private api: ApiService, private nav: Router, private http: HttpClient) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    /*if (this.api.user['api_token'] == undefined) {
      this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
      return false
    }*/
    if(this.api.user.uid) return true
    return this.http.get(this.api.baseurl + APIRoutes.me).toPromise().then((res: User) => {
      if (res['api_token'] == undefined) {
        this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
        return false
      }
      if (typeof res.area === "string") {
        res.area = res.area.split(',')
      }
      this.api.user = res
      return true
    }).catch(error => {
      this.nav.navigate([PageRoutes.login.addSlash()], {replaceUrl: true})
      return false
    });
  }

}
